import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import _ from 'lodash'

import './Jobs.scss'

// Components
import { ButtonLineDefault } from '../Elements/Elements'

const Jobs = () => {
  const {
    jobs: { edges: jobsList },
  } = useStaticQuery(
    graphql`
      {
        jobs: allWordpressWpVacatures(
          sort: { fields: date, order: ASC }
          limit: 3
        ) {
          edges {
            node {
              id
              title
              path
              acf {
                short_text
                button_text_more
                button_link
                second_button_link
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className="row-jobs col-12">
      {_.map(jobsList, job => (
        <div className="col-md-12 box d-flex flex-wrap align-items-center p-4">
          <div className="col-md-9">
            <h4 className="text-uppercase">
              <Link to={job.node.path}>
                {job.node.title}
              </Link>
            </h4>
            <div
              dangerouslySetInnerHTML={{ __html: job.node.acf.short_text }}
            />
          </div>
          <div className="col-md-3">
            {job.node.acf.button_link ? (
              <>
                <a
                  href="mailto:brian@koelewijn-crookwit.nl?Subject=Sollicitatie"
                  className="mb-4 button button-line-default"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {job.node.acf.button_text_more}
                </a>
                <a
                  href={`tel:${job.node.acf.second_button_link}`}
                  className="mb-0 button button-line-default"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bel ons
                </a>
              </>
            ) : (
              <ButtonLineDefault to={job.node.path}>
                {job.node.acf.button_text_more}
              </ButtonLineDefault>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
export default Jobs
