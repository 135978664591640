import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import _ from 'lodash'

import 'styles/VacaturePage.scss'

// Components
import {
  TitleDefault,
  ContentDefault,
  BannerDefault,
  BreadCrumbBar,
  ButtonDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import Jobs from 'components/Jobs'
import SEO from 'components/SEO'
import VideoModal from 'components/VideoModal'

function VacaturePage({ data, pageContext }) {
  const { wordpressPage: page } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="vacature-page">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="col-12 image-container p-0">
              <BackgroundImage
                fluid={
                  page.acf.header_image_vacatures.localFile.childImageSharp
                    .fluid
                }
              />
            </div>
            <div className="container">
              <div className="col-12 col-lg-6">
                <h1>{page.title}</h1>
              </div>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator="-"
              crumbLabel={page.title}
            />
          </div>
        </BreadCrumbBar>
        <div className="empty-space-100" />
        <section className="vacatures-intro">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5">
                <TitleDefault>
                  <span>{page.acf.text_jobs.title_left_jobs}</span>
                  <br />
                  <strong>{page.acf.text_jobs.subtitle_left_jobs}</strong>
                </TitleDefault>
                <ContentDefault>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.acf.text_jobs.text_left_jobs,
                    }}
                  />
                </ContentDefault>
              </div>
              <div className="col-md-6">
                <Img
                  fluid={
                    page.acf.text_jobs.image_video.localFile.childImageSharp
                      .fluid
                  }
                />
                <VideoModal>Bekijk de video</VideoModal>
              </div>
            </div>
          </div>
        </section>
        <div className="empty-space-100" />
        <section className="quote-row py-5">
          <div className="container">
            <div className="row">
              <div
                dangerouslySetInnerHTML={{ __html: page.acf.quote_tekst }}
                className="text-center m-auto text-uppercase"
              />
            </div>
          </div>
        </section>
        <section id="vacatures" className="vacatures-row pt-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="text-uppercase">Vacatures</h3>
              </div>
              <Jobs />
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default VacaturePage

export const VacatureQuery = graphql`
  {
    wordpressPage(wordpress_id: { eq: 100 }) {
      title
      acf {
        header_image_vacatures {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        text_jobs {
          button_tex_video
          link_video
          subtitle_left_jobs
          text_left_jobs
          title_left_jobs
          image_video {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 512) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        quote_tekst
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
